@font-face {
  font-family: 'Akkurat';
  src: url('../../assets/fonts/Akkurat-Font/Akkurat.ttf') format('woff2'),
    url('../../assets/fonts/Akkurat-Font/Akkurat.ttf') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Akkurat-bold';
  src: url('../../assets/fonts/Akkurat-bold/Akkurat-Bold.ttf') format('woff2'),
       url('../../assets/fonts/Akkurat-bold/Akkurat-Bold.ttf') format('woff');
  font-weight: 700; /* Bold */
  font-style: normal;
}

.S3-container {
  position: relative;
  background-image: url("../../assets/home/strategic_agenda_bg.png");
  background-size: cover;
  background-position: center;
  height: 88vh;
  color: white;
}

.S3-header {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}

.S3-logo {
  width: 60px;
  z-index: 1000;
}

.S3-strategic-agenda {
  font-family: 'Akkurat-Bold' !important;
  font-size: 40px !important;
  font-weight: 400 !important;
}

.S3-section-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  height: 100%;
  width: 100%;
  align-items: center;
  justify-items: center;
  text-align: center;
  position: relative;
}

.S3-section-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* Start content from the top */
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  border-right: 0.5px solid rgba(153, 153, 153, 0.5);
  transition: all 0.3s ease;
  height: 100%;
  width: 100%;
  position: relative;
  padding: 20px;
}

.S3-section-card:last-child {
  border-right: none;
}

.S3-section-card:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: scale(1.05);
}

.S3-section-title {
  font-size: 22px;
  font-weight: 400;
  text-align: left;
  padding: 20px;
  width: 100%;
  margin-bottom: 0.5rem;
  position: absolute;
  top: 80%;
  transform: translateY(-50%);
  min-height: 110px;
}

.S3-discover-link {
  font-size: 1rem;
  color: white;
  align-self: flex-start;
  padding-left: 0px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  text-decoration: none;
  transition: color 0.3s ease;
  z-index: 100;
}

.S3-discover-link:hover {
  color: #00aced;
}

@media (max-width: 768px) {
  .S3-container {
    min-height: auto;
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .S3-section-grid {
    display: flex;
    flex-direction: column;
  }

  .S3-section-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 15px;
    height: 200px;
    background-size: cover;
    background-position: center;
  }

  .S3-section-card:first-child {
    margin-top: 30px;
  }

  .S3-card-content {
    width: 100%;
    position: absolute;
    bottom: 15px; 
    text-align: center;
    background: rgba(0, 0, 0, 0.5); 
    color: white;
    padding: 10px;
  }

  .S3-section-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .S3-discover-link {
    font-size: 16px;
    padding: 5px;
    display: inline-block;
    text-decoration: none;
    color: #ffffff;
    cursor: pointer;
  }

  .S3-discover-link a {
    z-index: 10;
    position: relative;
    display: block;
  }
}

