@font-face {
    font-family: 'Akkurat';
    src: url('../../assets/fonts/Akkurat-Font/Akkurat.ttf') format('woff2'),
        url('../../assets/fonts/Akkurat-Font/Akkurat.ttf') format('woff');
    font-weight: 400;
    /* Regular */
    font-style: normal;
}

@font-face {
    font-family: 'Akkurat-bold';
    src: url('../../assets/fonts/Akkurat-bold/Akkurat-Bold.ttf') format('woff2'),
         url('../../assets/fonts/Akkurat-bold/Akkurat-Bold.ttf') format('woff');
    font-weight: 700; /* Bold */
    font-style: normal;
  }

.POF-s1-container {
    /* background-color: white; */
    width: 100%;
    z-index: 1000;
    margin-top: -490px;
}

.POF-logo-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    text-align: center;
    flex-direction: row;
    height: 450px;
    gap: 100px;
}

@media (max-width: 768px) {
    .POF-logo-section {
        flex-direction: column;
        gap: 30px;
    }
}

.POF-logo-image {
    width: 360px;
    height: 400px;
}

.POF-title-section {
    text-align: left;
}

@media (max-width: 768px) {
    .POF-logo-section {
        text-align: center;
        padding: 20px;
    }
    .POF-logo-image {
        margin-top: 80px;
        width: 230px;
        height: 250px;
    }
}

/* .POF-title1-text {
    font-size: 60px;
    font-weight: 400;
    color: #ffff;
    line-height: 1.2;
    font-weight: bold;
} */

.POF-title2-text {
  font-family: 'Akkurat-Bold';
    font-size: 40px;
    font-weight: 400;
    color: #001F5C;
    line-height: 1.2;
}

.POF-subtitle-text {
    font-family: 'Akkurat-Bold';
    font-size: 60px;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: -10px;
}