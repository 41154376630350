
body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

/* General layout container */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the container covers the entire viewport height */
}

/* Content container */
.content {
  flex: 1; /* Pushes the footer to the bottom */
}

/* Footer (ensure it's styled properly in Footer.css) */
footer {
  background-color: #002855; /* Same as Footer's background */
  color: white;
  text-align: center;
  /* padding: 20px; */
}
