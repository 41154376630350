@font-face {
  font-family: 'Akkurat';
  src: url('../../assets/fonts/Akkurat-Font/Akkurat.ttf') format('woff2'),
    url('../../assets/fonts/Akkurat-Font/Akkurat.ttf') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Akkurat-bold';
  src: url('../../assets/fonts/Akkurat-bold/Akkurat-Bold.ttf') format('woff2'),
       url('../../assets/fonts/Akkurat-bold/Akkurat-Bold.ttf') format('woff');
  font-weight: 700; /* Bold */
  font-style: normal;
}

/* Navigation Links */
.nav-links {
  display: flex;
  gap: 20px;
  list-style: none;
}

.nav-links li {
  margin: 0 10px;
}

.nav-links a {
  text-decoration: none;
  color: white;
  font-size: 16px;
}

.nav-links a:hover {
  color: #007bff;
}

/* Buttons */
.btn-container {
  display: flex;
  gap: 10px;
}

.btn {
  padding: 8px 25px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
}

.chakra-button.btn-primary {
  background: #0065F2;
  color: white;
  border: none;
  border-radius: 47px;
}

.chakra-button.btn-primary:hover {
  background: #3a99ff;
}

.chakra-button.btn-secondary {
  background: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 47px;
}

.chakra-button.btn-secondary:hover {
  background: white;
  color: black;
}

.chakra-button.scrolled.btn-secondary {
  background: transparent;
  color: black;
  border: 1px solid black;
  border-radius: 47px;
}

.chakra-button.btn-secondary.scrolled:hover {
  background: black;
  color: white;
}

/* Right Section (Buttons + Search Icon) */
.right-section {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* Search Icon */
.search-icon {
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.search-icon:hover {
  color: #007bff;
}

/* Hamburger menu and close icon */
.menu-icon {
  display: none;
  cursor: pointer;
}

.hamburger {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.hamburger .line {
  background: white;
  height: 3px;
  width: 25px;
}

.close-icon {
  font-size: 24px;
  color: white;
}

.btn-container-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
  display: none;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .menu-icon {
    display: flex;
  }

  .nav-links {
    position: absolute;
    top: 60px;
    left: 0;
    background: rgba(0, 0, 0, 0.9);
    width: 100%;
    flex-direction: column;
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }

  .nav-links.active {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }

  .nav-links li {
    margin: 15px 0;
    text-align: center;
  }

  .btn-container {
    display: none;
  }

  .btn-container-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
  }

  .search-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 24px;
  }

  .right-section {
    display: none;
  }
}


.hamburger-icon {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.hamburger-icon span {
  width: 20px;
  height: 2px;
  background-color: currentColor;
}

.close-icon {
  font-size: 1.5rem;
}


/* new code  */
.navbar {
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  z-index: 1000;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navbar.dynamic {
  background-color: rgba(0, 0, 0, 0.2);
  color: var(--text-color);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, calc(var(--scroll-opacity) * 0.1));
}

.navbar.scrolled {
  background-color: white;
  color: black;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-container {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 50px;
}

/* Logo */
.logo img {
  max-height: 50px;
}

.menu__text {
  font-family: 'Akkurat', sans-serif !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}

/* Menu dropdown styles */
.chakra-menu__list {
  border-radius: 8px;
}

.chakra-menu__menuitem {
  font-size: 16px !important;
  font-weight: 400 !important;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar-container {
    flex-direction: column;
  }
}

.option-divider {
  border-bottom: #DFDFDF 1px solid;
  padding-top: 5px;
}

.download-btn {
  border-radius: 70px !important;
  background-color: #001F5C !important;
  color: white !important;
}

.download-btn:hover {
  background-color: #103e9b !important;
}

.drawer-options {
font-size: 16px !important;
font-weight: 400 !important;
color: #001F5C !important;
}
