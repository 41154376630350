@font-face {
    font-family: 'Akkurat';
    src: url('../../assets/fonts/Akkurat-Font/Akkurat.ttf') format('woff2'),
        url('../../assets/fonts/Akkurat-Font/Akkurat.ttf') format('woff');
    font-weight: 400;
    /* Regular */
    font-style: normal;
}

@font-face {
    font-family: 'Akkurat-bold';
    src: url('../../assets/fonts/Akkurat-bold/Akkurat-Bold.ttf') format('woff2'),
        url('../../assets/fonts/Akkurat-bold/Akkurat-Bold.ttf') format('woff');
    font-weight: 700;
    /* Bold */
    font-style: normal;
}

.ref-s1-container {
    width: 100%;
    z-index: 1000;
}

.s1-logo-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    text-align: center;
    flex-direction: row;
    height: 450px;
    gap: 50px;
}

@media (max-width: 768px) {
    .s1-logo-section {
        flex-direction: column;
        gap: 15px;
    }
}

.s1-logo-image {
    width: 360px;
    height: 400px;
}

.s1-title-section {
    text-align: left;
}

@media (max-width: 768px) {
    .s1-logo-section {
        text-align: center;
        padding: 20px;
    }

    .s1-logo-image {
        width: 180px;
        height: 180px;
        margin-top: 60px;
    }
}

.s1-title1-text {
    font-size: 60px;
    font-weight: 400;
    color: #ffff;
    line-height: 1.2;
    font-weight: bold;
}

.s1-title2-text {
    font-size: 40px;
    font-weight: 400;
    color: #ffff;
    line-height: 1.2;
    font-weight: bold;
}

.s1-subtitle-text {
    font-size: 30px;
    font-weight: 400;
    color: #8FE45C;
    margin-top: 8px;
}

.s1-tabs-section {
    background-color: #F6F6F6;
    display: flex;
    justify-content: center;
    gap: 16px;
    padding-top: 24px;
    padding-bottom: 24px;
}

.s1-tab-button {
    padding: 8px 24px;
    border: 0.5px solid #e1e1e1;
    border-radius: 9999px;
    background-color: #E1E1E1;
    color: #3C3C3C;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.s1-tab-button:hover {
    background-color: #c8d1d5;
    color: #2a4365;
}

.s1-mission-section {
    display: flex;
    background-color: #F6F6F6;
    flex-wrap: wrap;
    justify-content: flex-start;
    /* Aligns all children to the start */
    align-items: center;
    padding: 48px;
    flex-direction: row;
    gap: 16px;
}

.s1-title {
    font-size: 40px;
    font-weight: 400;
    font-family: 'Akkurat-bold';
    text-align: left !important;
    flex-basis: 100%;
    margin-bottom: 20px;
}


@media (max-width: 768px) {
    .s1-mission-section {
        flex-direction: column;
    }
    .s1-title1-text {
        font-size: 30px;
        text-align: right;
        padding-top: 15%;
    }
    .s1-subtitle-text {
        font-size: 25px;
    }
}

.s1-mission-image-container {
    flex: 1;
    text-align: center;
    margin-bottom: 16px;
}

.s1-mission-image-ref {
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.s1-mission-text-container {
    flex: 1;
    text-align: left;
    padding-left: 80px;
}

.s1-mission-title {
    font-family: 'Akkurat-Bold' !important;
    font-size: 40px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 20px;
}

.s1-mission-description {
    font-family: 'Akkurat' !important;
    font-size: 22px;
    font-weight: 400;
    color: #515151;
    line-height: 1.8;
}

/* section 2 */

.ref-S2-container {
    background-color: #F6F6F6;
    padding: 2.5rem 0;
}

.ref-S2-sectionHeader {
    text-align: left;
    font-size: 1.5rem;
    font-weight: bold;
    color: #000000;
    margin-bottom: 1.5rem;
    padding-left: 5rem;
}

.ref-S2-gridContainer {
    display: grid;
    grid-gap: 1.5rem;
    padding: 0 1rem;
}

.ref-S2-gridContainerDesktop {
    padding: 0 2rem;
}

.ref-S2-gridItem {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    text-align: center;
}

.ref-S2-flexContainer {
    padding: 10px !important;
    display: flex !important;
    align-items: center;
    gap: 4rem;
}

.ref-S2-image {
    width: 75px;
    height: 65px;
    margin-bottom: 1rem;
}

.ref-S2-title {
    font-family: 'Akkurat' !important;
    font-size: 25px;
    font-weight: 400;
    color: #000000;
    /* line-height: 33.5px; */
}

.ref-S2-description {
    font-size: 0.875rem;
    color: #718096;
}

.ref-S2-agendaItem {
    background-color: white;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.ref-S2-agendaLabel {
    background-color: #E2E2E2;
    padding: 1rem 0;
    font-size: 22px;
    /* font-weight: bold; */
    color: #000000;
    height: auto;
    min-height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 768px) {
    .ref-S2-sectionHeader {
        padding-left: 1rem;
    }

    .ref-S2-gridContainerDesktop {
        padding: 0 1rem;
    }

    .ref-S2-flexContainer {
        gap: 1rem;
    }

    .ref-S2-image {
        width: 65px;
        height: 65px;
    }

    .ref-S2-title {
        font-size: 1rem;
        margin-top: 0.5rem;
        margin: auto;
    }

    .ref-S2-description {
        font-size: 0.75rem;
    }

    .s1-mission-text-container {
        text-align: center;
        padding-left: 0px;
    }
}


/* section 3  */

/* General Section Styling */
.ref-S3-focusedSectorsWrapper {
    background-color: #F6F6F6;
    padding: 2rem 1rem;
}

.ref-S3-sectionHeader {
    text-align: left;
    font-size: 1.5rem;
    font-weight: bold;
    color: #000000;
    margin-bottom: 1.5rem;
    padding-left: 5rem;
}

/* Swiper Styling */
.ref-S3-swiper {
    overflow: visible;
}

.ref-S3-sectorCard {
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.ref-S3-sectorImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.ref-S3-sectorTitleWrapper {
    background-color: #333;
    padding: 0.5rem;
    color: white;
}

.ref-S3-sectorTitle {
    font-size: 0.9rem;
    font-weight: bold;
    text-transform: uppercase;
}

/* Introduction Section */
.ref-S3-introWrapper {
    padding: 3rem 1rem;
    background-color: #F6F6F6;
}

.ref-S3-introFlex {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

@media (min-width: 768px) {
    .ref-S3-introFlex {
        flex-direction: row;
        justify-content: space-between;
    }
}

.ref-S3-introImage {
    width: 600px;
    height: 460px;
    margin-left: 50px;
}

@media (max-width: 768px) {
    .ref-S3-introImage {
        width: 100%;
        height: auto;
        margin-left: 0px;
    }
}

.ref-S3-introTextWrapper {
    font-family: 'Akkurat', sans-serif;
    text-align: center;
    /* max-width: 600px; */
    height: 460px;
    background-color: white;
    padding: 50px 45px 0px 40px;
    border-radius: 10px;

    display: flex;
    justify-content: center;
    flex-direction: column;
}

@media (min-width: 768px) {
    .ref-S3-introTextWrapper {
        text-align: left;
    }
}

.ref-S3-introHeader {
    font-size: 40px;
    font-weight: 700;
    color: #000000;
    line-height: 53px;
    margin-bottom: 20px;
}

.ref-S3-introDescription {
    font-size: 22px;
    font-weight: 400;
    color: #515151;
    margin-bottom: 45px;
}

.ref-S3-learnMoreButton {
    height: 45px;
    width: 160px;
    background-color: #007bff !important;
    color: white !important;
    font-size: 1rem !important;
    padding: 0.8rem 2rem !important;
    border-radius: 25px !important;
    transition: background-color 0.3s ease !important;
}

.ref-S3-learnMoreButton:hover {
    background-color: #0056b3 !important;
}


/* platform partners */

.PP-container {
    display: flex;
    flex-direction: row;
    padding: 20px;
    gap: 210px;
    justify-content: center;
    align-items: center;
}

.PP-box {
    background-color: white;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 500px;
    height: 230px;
}

.PP-image1 {
    width: 350px;
    height: 125px;
}

.PP-image2 {
    width: 350px;
    height: 180px;
}

@media (max-width: 768px) {
    .PP-container {
        flex-direction: column;
        padding: 10px;
        align-items: center;
        gap: 30px;
    }

    .PP-box {
        width: 100%;
    }

    .PP-image1 .PP-image2 {
        width: 90%;
        height: 150px;
    }
}

/* health india  */

.s1-mission-image,
.agenda-image {
    transform: translateX(-100%);
    opacity: 0;
    transition: all 1s ease-out;
}

.s1-mission-image.in-view,
.agenda-image.in-view {
    transform: translateX(0);
    opacity: 1;
}

.scroll-animate {
    opacity: 0;
    transform: translateX(-100%);
    transition: all 1s ease-out;
}

.in-view {
    opacity: 1;
    transform: translateX(0);
}

.agenda-image {
    transition: all 0.8s ease-out;
}

.ref-S2-gridContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 768px) {
    .ref-S3-introHeader {
        font-size: 25px;
    }

    .ref-S3-introDescription {
        font-size: 16px;
        margin-bottom: 15px;
    }

    .ref-S3-learnMoreButton {
        margin: auto;
    }

    .ref-S2-agendaLabel {
        font-size: 16px;
        height: auto;
    }
}