@font-face {
    font-family: 'Akkurat';
    src: url('../../assets/fonts/Akkurat-Font/Akkurat.ttf') format('woff2'),
      url('../../assets/fonts/Akkurat-Font/Akkurat.ttf') format('woff');
    font-weight: bold;
    font-style: normal;
  }

.carousel-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    padding: 2rem 0;
    max-height: 350px;
}

.carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 280px;
}

.carousel-item {
    position: absolute;
    width: 22%;
    transition: transform 0.5s ease, opacity 0.5s ease, z-index 0.5s ease;
    cursor: pointer;
}

.carousel-item img {
    width: 100%;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    position: relative;
    display: block;
}

.carousel-item .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(to top, rgba(0, 0, 0, 2.7), transparent);
    border-radius: 12px;
}

.carousel-caption {
    text-align: center;
    color: white;
    font-weight: bold;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.2rem;
}

.position-0 {transform: translateX(0%) scale(1);z-index: 5;}
.position-1 {transform: translateX(90%) scale(0.9);z-index: 4;}
.position-2 {transform: translateX(135%) scale(0.8);z-index: 3;}
.position-3 {transform: translateX(180%) scale(0.7);z-index: 2;}
.position-4 {transform: translateX(-90%) scale(0.9);z-index: 4;}
.position-5 {transform: translateX(-135%) scale(0.8);z-index: 3;}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .carousel-container {height: 180px;}
    .carousel-item {width: 40%;}
    .carousel-caption {font-size: 1rem;}
    .position-1 {transform: translateX(40%) scale(0.85);}
    .position-2 {transform: translateX(80%) scale(0.75);}
    .position-3 {transform: translateX(120%) scale(0.65);}
    .position-4 {transform: translateX(-40%) scale(0.85);}
    .position-5 {transform: translateX(-80%) scale(0.75);}
}