@font-face {
    font-family: 'Akkurat';
    src: url('../../assets/fonts/Akkurat-Font/Akkurat.ttf') format('woff2'),
      url('../../assets/fonts/Akkurat-Font/Akkurat.ttf') format('woff');
    font-weight: bold;
    font-style: normal;
  }

.S4-container {
    background-color: #F6F6F6;
    padding-top: 6rem;
}

.S4-main-banner {
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 100px;
    margin-bottom: 2rem;
    opacity: 0;
    transform: translateX(-50px);  /* Starting position off-screen */
    transition: opacity 1s ease, transform 1s ease;
}

.S4-main-banner.slide-in-left {
    opacity: 1;
    transform: translateX(0);  /* Bring it into view */
}

.S4-image-container {
    flex: 1;
    display: flex;
    width: 680px;
    height: 770px;
}

.S4-banner-image {
    width: 100%;
}

.S4-text-container {
    flex: 1;
    /* max-width: 50%; */
    /* padding: 1rem; */
    /* padding-left: 4rem; */
}

.S4-banner-heading {
    font-size: 40px;
    font-weight: 400;
    line-height: 53.2px;
    margin-bottom: 30px;
}

.S4-banner-text {
    padding: 30px 16 60px 0px;
    font-size: 22px;
    font-weight: 400;
    color: #515151;
    line-height: 30px;
}

.S4-join-button {
    background-color: #007bff !important;
    color: #fff !important;
    font-weight: bold;
    border-radius: 5px !important;
    padding: 0.75rem 1.5rem;
    transition: background-color 0.3s ease;
    margin-top: 60px;
}

.S4-join-button:hover {
    background-color: #0056b3 !important;
}

.S4-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    gap: 1.5rem;
    /* padding: 10rem; */
    padding: 10rem 3rem 2rem 10rem;
    /* p="15px 20px 0px 10px" */
}

.S4-card {
    position: relative;
    background-size: cover;
    background-position: center;
    height: 225px;
    width: 291px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    overflow: hidden;
    opacity: 0;
    transform: translateX(-50px);
    transition: opacity 1s ease, transform 1s ease;
}

.S4-card:hover::before {
    transform: scale(1.1);
}

.S4-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    z-index: 1; 
    border-radius: 12px;
    transition: transform 0.5s ease-in-out;
}

.S4-card:hover {
    cursor: pointer;
    background-size: 110%; 
    transition: background-size 0.5s ease-in-out;
}


.S4-card.slide-in-left {
    opacity: 1;
    transform: translateX(0);
}

.S4-card-icon {
    width: 65px;
    height: 60px;
    margin-bottom: 1rem;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    z-index: 1000;
}

.S4-card-title {
    color: #fff;
    font-size: 1.25rem;
    font-weight: 500;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
    z-index: 100;
}

/* .S4-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    z-index: 1; 
    border-radius: 12px; 
} */


@media (max-width: 768px) {
    .S4-main-banner {
        flex-direction: column; 
        align-items: center;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .S4-text-container {
        flex: 1;
        text-align: center;
        padding: 1rem;
    }

    .S4-image-container {
        width: 100%;
        height: 300px;
    }

    .S4-banner-heading {
        font-size: 1.5rem;
        text-align: center;
    }

    .S4-banner-text {
        font-size: 0.9rem;
        text-align: center;
        margin-bottom: 1rem;
    }

    .S4-join-button {
        padding: 1rem;
    }

    .S4-cards-container {
        display: grid;
        justify-items: center;
        padding: 2rem;
        grid-template-columns: 1fr 1fr; 
    }

    .S4-card {
        height: 180px; 
    }

    .S4-card-title {
        font-size: 1.1rem;
    }
}

@media (max-width: 480px) {
    .S4-main-banner {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .S4-image-container {
        height: 250px;
    }

    .S4-banner-heading {
        font-size: 1.25rem;
        margin-bottom: 0.75rem;
    }

    .S4-banner-text {
        font-size: 0.85rem;
        margin-bottom: 1rem;
    }

    .S4-join-button {
        font-size: 0.9rem;
        padding: 0.5rem 1rem;
    }

    .S4-cards-container {
        display: grid;
        justify-items: center;
        padding: 1rem;
        grid-template-columns: 1fr; 
    }

    .S4-card {
        height: 150px; 
    }

    .S4-card-title {
        font-size: 1rem;
    }
}