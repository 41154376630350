/* home page css new  */
@font-face {
  font-family: 'Akkurat';
  src: url('../../assets/fonts/Akkurat-Font/Akkurat.ttf') format('woff2'),
    url('../../assets/fonts/Akkurat-Font/Akkurat.ttf') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Akkurat-bold';
  src: url('../../assets/fonts/Akkurat-bold/Akkurat-Bold.ttf') format('woff2'),
    url('../../assets/fonts/Akkurat-bold/Akkurat-Bold.ttf') format('woff');
  font-weight: 700;
  font-style: normal;
}

.REF-heading-1 {
  font-size: 35px;
  font-weight: 400;
  line-height: 46.45px;
  color: #6BFF63;
  text-align: left;
  text-decoration-skip-ink: none;
}

.REF-heading-2 {
  font-family: 'Akkurat-Bold';
  font-size: 45px;
  font-weight: 700;
  line-height: 60px;
  padding-top: 20px;
  padding-bottom: 35px;
  text-decoration-skip-ink: none;
}

.REF-img {
  width: 100%;
  height: 426px;
  border-radius: 20px;
  object-fit: cover;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
}

.REF-img:hover {
  transform: scale(1.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
  z-index: 2;
}

@media (max-width: 768px) {
  .REF-img {
    height: 100px;
  }
}