@font-face {
  font-family: 'Akkurat';
  src: url('../../assets/fonts/Akkurat-Font/Akkurat.ttf') format('woff2'),
    url('../../assets/fonts/Akkurat-Font/Akkurat.ttf') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Akkurat-bold';
  src: url('../../assets/fonts/Akkurat-bold/Akkurat-Bold.ttf') format('woff2'),
    url('../../assets/fonts/Akkurat-bold/Akkurat-Bold.ttf') format('woff');
  font-weight: 700;
  font-style: normal;
}

.footer {
  background-color: #1475CF;
  color: white;
  font-family: Arial, sans-serif;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  /* Allows wrapping for smaller screens */
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 30px;
}

.footer-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 0px 30px;
}

.footer-logo img {
  max-width: 200px;
  height: 130px;
}

.footer-logo p {
  margin: 5px 0;
}

.footer-links {
  flex: 2;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  text-align: left;
  /* Wrap links on smaller screens */
}

.footer-links ul {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.footer-links li {
  margin: 5px 0;
  cursor: pointer;
}

.footer-social {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: right;
  margin-top: 20px;
}

.social-icons {
  display: flex;
  /* Align icons in a row */
  justify-content: center;
  /* Center icons horizontally */
  margin-bottom: 10px;
}

.social-icons a {
  color: white;
  margin: 0 5px;
  text-decoration: none;
  font-size: 18px;
}

.social-icons img {
  width: 30px;
  /* Set a fixed width for the icons */
  height: 30px;
  /* Ensure icons are proportional */
}

.download-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: white;
  color: #001F5C;
  border: none;
  cursor: pointer;
  border-radius: 50px !important;
  border-radius: 47px;
}

.footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
  padding: 10px;
  font-size: 14px;
  opacity: 50%;
}

.footer-bottom-text {
  font-size: 16px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    /* Stack all items vertically */
    align-items: center;
    /* Center-align on mobile */
  }

  .footer-logo {
    text-align: center;
  }

  .footer-links {
    flex-direction: column;
    justify-content: center;
    /* Center links on mobile */
    gap: 20px;
    /* Add spacing between link groups */
  }

  .footer-links ul {
    text-align: center;
    /* Center-align list items */
  }

  .footer-social {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* Center social icons and button */
    margin-top: 20px;
  }

  .social-icons {
    justify-content: center;
    /* Align social icons in a row */
  }

  .footer-bottom {
    flex-direction: column !important;
    position: static !important;
    font-size: 12px;
  }
  
  .footer-bottom-text {
    position: static !important;
    text-align: center !important;
    margin-top: 5px;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  /* .footer-logo img { */
  /* max-width: 40px; Smaller logo on mobile */
  /* } */

  .social-icons a {
    font-size: 16px;
    /* Adjust icon size */
  }

  .download-button {
    padding: 8px 15px;
    /* Smaller button on mobile */
  }

  .footer-bottom {
    flex-direction: column !important;
    position: static !important;
    font-size: 12px;
  }
  
  .footer-bottom-text {
    position: static !important;
    text-align: center !important;
    margin-top: 5px;
    font-size: 16px;
  }
}