@font-face {
    font-family: 'Akkurat';
    src: url('../../assets/fonts/Akkurat-Font/Akkurat.ttf') format('woff2'),
        url('../../assets/fonts/Akkurat-Font/Akkurat.ttf') format('woff');
    font-weight: 400;
    /* Regular */
    font-style: normal;
}

@font-face {
    font-family: 'Akkurat-bold';
    src: url('../../assets/fonts/Akkurat-bold/Akkurat-Bold.ttf') format('woff2'),
        url('../../assets/fonts/Akkurat-bold/Akkurat-Bold.ttf') format('woff');
    font-weight: 700;
    /* Bold */
    font-style: normal;
}

.platform-section {
    background-color: #F6F6F6;
    padding: 3rem 1.5rem;
}

.platform-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

@media (min-width: 768px) {
    .platform-top {
        flex-direction: row;
        gap: 200px;
    }
}

.platform-image {
    max-width: 100%;
    max-width: 221px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
}

.platform-content {
    max-width: 600px;
    text-align: center;
}

@media (min-width: 768px) {
    .platform-content {
        text-align: left;
    }
}

.platform-heading {
    font-family: 'Akkurat-bold', sans-serif !important;
    font-size: 40px;
    font-weight: 400;
    /* margin-bottom: 1rem; */
}

.platform-text {
    font-family: 'Akkurat', sans-serif !important;
    font-size: 20px;
    font-weight: 400;
    /* line-height: 1.5; */
    color: #515151;
    /* margin-bottom: 1.5rem; */
}

.platform-button {
    background-color: #cecdcd !important;
    color: black;
    border-radius: 50px !important;
}

.platform-button:hover {
    background-color: #EDEDED !important;
}

.platform-bottom {
    display: flex !important;
    gap: 2rem;
    max-width: 100%;
    width: 100%;
    padding-top: 80px;
}

.platform-left {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    width: 50%;
}

.platform-card {
    position: relative;
    overflow: hidden;
    border-radius: 0.5rem;
    display: flex;
    width: 100%;
}

.platform-card-image {
    /* width: 100%; */
    height: 370px;
    object-fit: cover;
}

.platform-card-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    padding: 0.5rem;
    text-align: center;
}

.platform-glance {
    flex: 1;
    background-color: #1475CF;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.glance-heading {
    font-family: 'Akkurat-Bold' !important;
    font-size: 40px !important;
    font-weight: 400 !important;
    color: #ffffff;
    margin-bottom: 50px;
}

.glance-divider {
    width: 50%;
    height: 2px;
    background-color: #ffffff;
    margin: 1rem 0;
}

.glance-items {
    font-family: 'Akkurat' !important;
    display: flex;
    justify-content: space-evenly;
    font-size: 22px;
    font-weight: 400;
    line-height: 30px;
    color: #ffffff;
    width: 100%;
}

.glance-items .glance-divider {
    height: 2rem;
    width: 1px;
    background-color: #e5e7eb;
}

@media (max-width: 768px) {
    .platform-bottom {
        flex-direction: column;
        align-self: center !important;
    }

    .platform-button {
        margin: auto;
    }

    .platform-top {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .platform-image {
        display: block;
        margin: 0 auto;
    }

    .glance-items {
        font-size: 16px;
        font-weight: 200;
    }

    .platform-left,
    .platform-glance {
        flex: unset;
        width: 100%;
    }
}