/* General Container Styling */
.S5-container {
  width: 100%;
  padding: 2rem 3rem;
  background-color: #F6F6F6;
}

@media (max-width: 768px) {
  .S5-container {
    padding: 1.5rem;
  }
}

/* Event Section Styling */
.S5-eventsSection {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  align-items: center;
}

@media (max-width: 768px) {
  .S5-eventsSection {
    flex-direction: column;
    align-items: flex-start;
  }
}

/* Event Card Layout */
.S5-eventCard {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.S5-eventImage {
  object-fit: cover;  /* Ensure image maintains aspect ratio */
  width: 100%;
  height: 100%;
  display: block;
}

/* Title Styling Over Image */
.S5-eventText {
  position: absolute;
  bottom: 0;  /* Title directly at the bottom of the image */
  left: 0;
  right: 0;
  text-align: left;
  color: white;
  font-size: 1.25rem;
  /* font-weight: bold; */
  padding: 10px;
  background: rgba(0, 0, 0, 0.6);  /* Semi-transparent black background */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);  /* Title shadow */
  border-radius: 0 0 10px 10px;  /* Rounded bottom corners for the title background */
}

/* Shadow directly at the bottom of the image */
.S5-eventCard::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;  /* Height of the shadow */
  /* background: rgba(0, 0, 0, 0.6);  Dark shadow at the bottom */
  border-radius: 0 0 10px 10px;  /* Matching rounded corners */
}

/* Event Section Text Styling */
.S5-eventsText {
  height: 300px;
  background-color: #1475CF;
  padding: 2rem;
  margin-right: 2rem;
  max-width: 25%;
  flex: 1;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .S5-eventsText {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
}

.S5-heading {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #ffff;
}

@media (max-width: 768px) {
  .S5-heading {
    font-size: 1.5rem;
  }
}

.S5-subtext {
  font-size: 1rem;
  color: #ffff;
}

@media (max-width: 768px) {
  .S5-subtext {
    font-size: 0.9rem;
  }
}

/* Event Arrows */
.S5-eventArrows {
  gap: 0.5rem;
  padding-top: 10px;
}

@media (max-width: 768px) {
  .S5-eventArrows {
    margin-top: 1rem;
    align-self: flex-end;
  }
}

/* Event Buttons */
.S5-eventButton-pre {
  background-color: white;
  color: #ffffff;
  border-radius: 10px !important;
  padding: 24px 10px;
  margin: 50px 0px 0px 20px;
  transition: background-color 0.3s, color 0.3s;
  
}

.S5-eventButton-pre:hover {
  background-color: black !important; /* Change to black on hover */
  color: white !important;
}

/* Next Button */
.S5-eventButton-nxt {
  color: white;
  border-radius: 10px !important;
  padding: 24px 10px;
  margin: 50px 0px 0px 20px;
  transition: background-color 0.3s, color 0.3s;
}

.S5-eventButton-nxt:hover {
  background-color: black !important; /* Change to black on hover */
  color: white !important;
}


/* Responsive Event Grid */
.S5-eventsGrid {
  height: 300px;
  flex: 2;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  min-height: 270px;
}

@media (max-width: 768px) {
  .S5-eventsGrid {
    /* grid-template-columns: repeat(2, 1fr); */
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .S5-eventsGrid {
    grid-template-columns: 1fr;
  }
}
