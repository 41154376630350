.S5-container {
    width: 100%;
    padding: 2rem;
    background-color: #F6F6F6;
  }
  
  @media (max-width: 768px) {
    .S5-container {
      padding: 1.5rem;
    }
  }
  
  .S5-eventsSection {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    align-items: center;
  }
  
  @media (max-width: 768px) {
    .S5-eventsSection {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  
  .S5-eventsText {
    height: 320px;
    background-color: white;
    padding: 2rem;
    margin-right: 2rem;
    max-width: 30%;
    flex: 1;
    padding-right: 2rem;
  }
  
  @media (max-width: 768px) {
    .S5-eventsText {
        max-width: 100%;
        margin-right: 0;
      margin-bottom: 1rem;
    }
  }
  
  .S5-heading {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  @media (max-width: 768px) {
    .S5-heading {
      font-size: 1.5rem;
    }
  }
  
  .S5-subtext {
    font-size: 1rem;
    color: #555;
  }
  
  @media (max-width: 768px) {
    .S5-subtext {
      font-size: 0.9rem;
    }
  }


  .S5-eventArrows {
    gap: 0.5rem;
    padding-top: 10px;
  }
  
  @media (max-width: 768px) {
    .S5-eventArrows {
      margin-top: 1rem;
      align-self: flex-end;
    }
  }
  
  .S5-eventButton-pre {
    background-color: white !important;
    color: #1475CF !important;
    border-radius: 10px !important;
    padding: 0.5rem;
    margin: 50px 0px 0px 20px;
    transition: background-color 0.3s;
  }
  
  .S5-eventButton-pre:hover {
    background-color: #1475CF !important;
    color: white !important;
  }

  .S5-eventButton-nxt {
    background-color: #1475CF !important;
    color: white !important;
    border-radius: 10px !important;
    padding: 0.5rem;
    margin: 50px 0px 0px 20px;
    transition: background-color 0.3s;
  }
  
  .S5-eventButton-nxt:hover {
    background-color: white !important;
    color: #1475CF !important;
  }



  
  .S5-eventsGrid {
    flex: 2;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }
  
  @media (max-width: 768px) {
    .S5-eventsGrid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 480px) {
    .S5-eventsGrid {
      grid-template-columns: 1fr;
    }
  }
  
  .S5-eventCard {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .S5-eventImage {
    object-fit: fill;
    width: 100%;
    height: 100%;
  }
  
  .S5-eventText {
    text-align: left;
    font-size: 1.1rem;
    margin-top: 0.5rem;
  }
  
  @media (max-width: 768px) {
    .S5-eventText {
      font-size: 1rem;
    }
  }
  
  .S5-mediaSection {
    margin-top: 2rem;
  }
  
  .S5-mediaHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  @media (max-width: 768px) {
    .S5-mediaHeader {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  
  .S5-mediaArrows {
    gap: 0.5rem;
    padding-top: 10px;
  }
  
  @media (max-width: 768px) {
    .S5-mediaArrows {
      margin-top: 1rem;
      align-self: flex-end;
    }
  }
  
  .S5-arrowButton {
    background-color: #00000080 !important;
    color: white !important;
    border-radius: 50% !important;
    padding: 0.5rem;
    transition: background-color 0.3s;
  }
  
  .S5-arrowButton:hover {
    background-color: #ddd !important;
  }
  
  .S5-mediaGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
  }
  
  @media (max-width: 768px) {
    .S5-mediaGrid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 480px) {
    .S5-mediaGrid {
      grid-template-columns: 1fr;
    }
  }
  
  .S5-mediaCard {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .S5-mediaImage {
    object-fit: fill;
    width: 100%;
    height: 100%;
  }
  