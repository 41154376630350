.SS-carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2rem;
  background-color: #fff;
  position: relative;
}

.SS-carousel {
  position: relative;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 350px;
}

.SS-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  margin-right: -20%;
}

.SS-carousel-item {
  position: absolute;
  transition: transform 0.5s ease, scale 0.5s ease, opacity 0.5s ease, height 0.5s ease;
  border-radius: 16px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  background: #f4f4f4;
}

.SS-carousel-item::after {
  content: "";
  position: absolute;
  inset: 0; 
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
  border-radius: 16px;
  pointer-events: none; 
}

.SS-carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 50%);
}

.SS-overlay {
  position: absolute;
  bottom: 0px;
  text-align: center;
  width: 100%;
  color: white;
  font-size: 1.2rem;
  /* font-weight: bold; */
  padding: 10px 20px;
  border-radius: 0 0 16px 16px;
  z-index: 5;
}

.SS-nav-button {
  position: absolute;
  top: 15%;
  transform: translateY(-50%);
  color: gray;
  padding: 0.8rem;
  cursor: pointer;
  z-index: 5;
}

.SS-left {
  left: 40px;
}

.SS-right {
  right: 40px;
}

.SS-text-content {
  margin-left: 100px;
  max-width: 390px;
}

.SS-text-content h2 {
  font-size: 40px;
  font-weight: 400;
  color: #000;
  padding-bottom: 30px;
}

.SS-text-content p {
  font-size: 22px;
  font-weight: 400;
  color: #515151;
}
